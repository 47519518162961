<template>
  <module
      ref="module"
      id="multipleAddAccount"
      titleIcon="fa fa plus"
      :title="$t('multipleaccountadd').toUpperCase()"
      :use-default-list="false"
    >
    <template slot="toolbar-global">
      <p-button v-if="!Constants.methods.isMobile()" type="default" v-on:click="prevPage()">
        <i slot="label" class="nc-icon nc-minimal-left"></i>
        {{ $t('back') }}
      </p-button>
    </template>
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <div class="card col-md-12">
          <div class="card-header">
          </div>
          <div class="card-body">

            <table class="col-md-12">
              <tr v-for="(r, index) in rows">

                <td class="text-center mobile-fix">
                  <label style="margin: 0px"> &nbsp; </label>
                  <div>
                    <p-button
                      class="btn btn-danger"
                      v-on:click="removeRow(index)"
                      v-if="rows.length > 1">
                      <i class="nc-icon nc-simple-remove"></i>
                    </p-button>
                  </div>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accbroker') }}</label>
                  <div v-if="r.broker == 'Other'" class="row">
                    <div class="col-md-2">
                      <button
                        class="btn btn-default"
                        style="margin-top: 0"
                        v-on:click="r.broker = null;r.tmp_broker = null;">
                        <i class="nc-icon nc-minimal-left"></i>
                      </button>
                    </div>
                    <div class="col-md-10">
                      <fg-input
                        v-model="r.tmp_broker"
                        type="text"
                        :placeholder="`${$t('helper_accbroker')}`">
                      </fg-input>
                    </div>
                  </div>
                  <div v-else>
                    <el-select
                      class="form-control"
                      v-model="r.broker"
                      :placeholder="`${$t('helper_accbroker')}`"
                      style="margin-bottom: 10px;border: 0; padding: 0;"
                      v-if="(r.broker === null || r.broker !== 'Other')"
                      @change="filterPlatform(r.broker)">

                      <el-option
                        class="select-default"
                        v-for="(broker, index) in brokerlist"
                        :key="index"
                        :value="broker.name">
                        {{broker.name}}
                      </el-option>
                    </el-select>
                  </div>
                </td>
                <td colspan="4" class="mobile-fix">
                  <label>{{ platformtxt }}</label>
                  <select
                    v-model="r.platform"
                    class="form-control"
                    :placeholder="platformtxt"
                    style="margin-bottom: 10px; padding: 0;">
                    <option class="select-default"
                               v-for="(item, index) in platformList"
                               :value="item.prop"
                               :key="index">
                      {{item.label}}
                    </option>
                  </select>
                </td>


                <td class="mobile-fix">
                  <label>{{$t('helper_accserver')}}</label>
                  <div v-if="(r.server == 'Other' || r.broker == 'Other')" class="row">
                    <div class="col-md-2">
                      <button
                        class="btn btn-default"
                        style="margin-top: 0"
                        v-on:click="r.server = null;r.tmp_server = null;">
                        <i class="nc-icon nc-minimal-left"></i>
                      </button>
                    </div>
                    <div class="col-md-10">
                      <fg-input
                        v-model="r.tmp_server"
                        type="text"
                        :placeholder="`${$t('helper_accserver')}`">
                      </fg-input>
                    </div>
                  </div>
                  <div v-else>
                    <el-select
                      class="form-control"
                      v-model="r.server"
                      :placeholder="`${$t('helper_accserver')}`"
                      style="margin-bottom: 10px;border: 0; padding: 0;"
                      :disabled="!r.platform || !r.broker"
                      v-if="(r.broker === null || (r.broker !== 'Other' && r.server !== 'Other'))">
                      <el-option
                        class="select-default"
                        v-for="server in filterServers(r)"
                        :key="server.prop"
                        :label="getOtherLabel(server.server)"
                        :value="server.server">
                      </el-option>
                    </el-select>
                  </div>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accentry') }}</label>
                  <fg-input
                    v-model="r.account"
                    type="text"
                    replace="[^0-9]"
                    :disabled="!r.platform"
                    :placeholder="`${$t('helper_accentry')}`">
                  </fg-input>
                </td>
                <td class="mobile-fix">
                  <label>{{ $t('helper_accpwd') }}</labeL>
                  <fg-input
                    :disabled="!r.platform"
                    v-model="r.pwd"
                    type="password"
                    :placeholder="`${$t('helper_accpwd')}`">
                  </fg-input>
                </td>
              </tr>
            </table>
            <hr>
            <p-button type="info" v-on:click="addRow" class="float-left">
              <i class="nc-icon nc-simple-add"></i>
              <span v-if="Constants.methods.isMobile()">{{ $t('add') }}</span>
              <span v-else>{{ $t('addrow') }}</span>
            </p-button>
            <p-button type="success" v-on:click="addAll">
              <i class="nc-icon nc-check-2"></i>
              {{ $t('create') }}
            </p-button>
          </div>
          <div class="card-footer"></div>
        </div>

      </div>
    </div>
  </module>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import {Button, Modal} from 'src/components/UIComponents'
import swal from 'sweetalert2'
import Constants from "@/assets/constants";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

const user_data = JSON.parse(localStorage.getItem('user-info'));

const timeoutcount = 1500;
const longertimeout = 5000;

export default {
  components: {
    [Button.name]: Button, Modal
  },
  computed: {
    Constants() {
      return Constants
    }

  },
  data () {
    return {
      inprogress: true,
      alert_me: false,
      alert_msg: false,
      alert_class: 'text-danger',
      platformtxt: this.$t('platformtxt'),
      rows: [
        {
          account: null,
          broker: null,
          server: null,
          pwd: null,
          tmp_broker: null,
          tmp_server: null,
          remove: false,
          platform: null,
        }
      ],
      servers: [],
      brokerlist: [],
      isadmin: false,
      ueml: null,
      failedadding: false,
      inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('multipleaccountadd') +". "+ this.$t('pleasewait'),
      platformList: []
    }
  },
  methods: {
    _addAccount(idx) {
      if (idx < this.rows.length) {
        const _proceed = r => {
          // Remove succesful from entry
          this.rows.splice(idx, 1);
          // Next one
          this._addAccount(idx);
        };
        const _failadd = r => {
          this.failedadding = true;
          // Next one
          this._addAccount(idx+1);
        };
        const acc = this.rows[idx];

        let json_data = {
          platform: acc.platform,
          broker: acc.broker,
          server: acc.server,
          centaccount: false,
          password: acc.pwd,
        };

        if (json_data.broker == 'Other' || json_data.server == 'Other') {
          json_data.server = acc.tmp_server
        }

        if (json_data.broker == 'Other') {
          json_data.broker = acc.tmp_broker
        }

        this.$addAccount_v2({
          eml: this.ueml,
          account: acc.account,
          newacc: true,
          json_data: json_data
        }).then(_proceed, _failadd);

      } else {
        this.inprogress = false;
        // IF Failed Adding Accounts
        if (this.failedadding) {
          this.$toast.error(this.$t('failaddingaccount'));
          this.resetRows()
        } else { // Success adding all accounts
          localStorage.removeItem('hc_accounts-'+ this.ueml);
          this.resetRows();
          swal({
            title: this.$t('successtxt'),
            text: this.$t('multipleaccountadd'),
            type: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false
            }).then(this.changeLocation)
        }
      }
    },
    changeLocation(){
      location.href = this.isadmin ? "/admin_accounts/" + this.ueml : "/accounts/main";
    },
    addAll() {
      if (this.notAllFieldsPopulated()) {
        this.$toast.warning(this.$t('populatefieldbelow'));
        this.alert_class = 'text-danger';
        return;
      }
      this.inprogresstxt = this.$t('addingaccounts');
      this.inprogress = true;
      this._addAccount(0);
    },
    removeRow(idx) {
      this.rows.splice(idx, 1);
    },
    notAllFieldsPopulated() {
      let allpop = false;
      for (var i=0;i < this.rows.length;i++) {
        if (!this.rows[i].account) {
          return true;
        }
        if (!this.rows[i].account.match(/^[0-9]+$/)) {
          return true;
        }
        if (this.rows[i].broker === 'Other') {
          if (!this.rows[i].tmp_broker) {
            return true;
          }
          if (!this.rows[i].tmp_server) {
            return true;
          }
        } else {
          if (!this.rows[i].server) {
            return true;
          }
          if (!this.rows[i].broker) {
            return true;
          }
        }
        if (this.rows[i].server === 'Other' && !this.rows[i].tmp_server) {
          return true;
        }
        if (!this.rows[i].pwd) {
          return true;
        }
      }
      this.rows[i-1].remove = true;
      return allpop;
    },
    clearAlert() {
      this.alert_me = false;
      this.alert_msg = null;
      this.alert_class = 'text-danger';
    },
    addRow() {
      if (this.notAllFieldsPopulated()) {
        this.$toast.warning(this.$t('populatefieldbelow'));
        return;
      }
      this.rows.push({
        account: null,
        broker: null,
        server: null,
        pwd: null,
        tmp_broker: null,
        tmp_server: null,
        remove: false,
        platform: null,
      });
    },
    resetRows() {
      this.rows = [
        {
          account: null,
          broker: null,
          server: null,
          pwd: null,
          tmp_broker: null,
          tmp_server: null,
          remove: false,
          platform: null,
        }
      ]
    },
    prevPage() {
      window.history.back();
    },
    loadServers(resp) {
      if (!resp.success) {
        return;
      }

      this.servers = resp.data;

      for(var j=0;j < resp.data.length;j++) {
        const b = resp.data[j].broker;

        if (this.servers[b] === undefined) {
          this.servers[b] = [];
        }
        this.servers[b].push({
          'prop': resp.data[j].server,
          'label': resp.data[j].server
        });
      }

      this.servers.push({
        broker: "Other",
        server: "Other"
      })

      this.inprogress = false;
    },
    failop (error) {
      if (error.data && error.data.msg) {
        error = error.data.msg;
      }
      this.$toast.error(error);
    },
    filterServers(row) {
      let servers = this.servers.filter(server => {
        return server.broker == row.broker && server.platform == row.platform;
      });

      servers.push({
        broker: "Other",
        server: "Other"
      });

      return servers;
    },
    getOtherLabel(label) {
      if (label == 'Other') {
        return this.$t('othertxt');
      }
      return label;
    },
    filterPlatform(brokerSelected) {
      this.platformList = []
      let broker_item = this.brokerlist.filter(el => el.name == brokerSelected)
      if (broker_item[0].platform == 'both') {
        return this.platformList.push(
            {
              prop: 'mt4',
              label: 'MT4'
            },
            {
              prop: 'mt5',
              label: 'MT5'
            }
        )
      }
      if (broker_item[0].platform == 'mt4') {
        return this.platformList.push({
          prop: 'mt4',
          label: 'MT4'
        })
      }
      if (broker_item[0].platform == 'mt5') {
        return this.platformList.push({
          prop: 'mt5',
          label: 'MT5'
        })
      }
    },
    loadBrokers(resp){
      this.brokerlist = resp.data;
      this.brokerlist.push({
        'name': "Other",
        'platform': "both"
      });
    },
  },
  mounted () {
    this.isadmin = user_data.roles.indexOf('admin') >= 0;

    this.ueml = this.$route.params.userid ? this.$route.params.userid : user_data.email;

    this.$getServers().then(this.loadServers, this.failop);
    this.$getBrokerPlatform().then(this.loadBrokers, this.failop)
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  }
}
</script>
<style>
.el-input__inner::placeholder {
  color: black!important;
}
@media (max-width: 768px) {
  .mobile-fix {
    width: 100% !important;
    display: block !important;
  }
}
</style>
